import { FC } from 'react';

import { useLoginContext } from '@/components/auth/login/LoginContext';
import { enterpriseSSOLoginUrl } from '@/lib/urls';
import Button from '@/rollbar-ui/Button';

export const IdpLoginButton: FC = () => {
  const { accountSlug } = useLoginContext();

  if (!accountSlug) {
    return null;
  }

  return (
    <Button
      className="mb-4"
      as="a"
      variant="secondary"
      href={enterpriseSSOLoginUrl(accountSlug)}
    >
      <span className="font-semibold text-gray-700 text-sm">
        Log in with Identity Provider
      </span>
    </Button>
  );
};
