import { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  className: string;
};

const Card: FC<Props> = ({ children, className }) => (
  <section className={`rounded-lg p-6 flex flex-col bg-white ${className}`}>
    {children}
  </section>
);

export { Card };
