import { signIn } from 'supertokens-web-js/recipe/emailpassword';

import { AuthActionError, AuthActionInvalid } from '@/api/auth/error';
import { frontendControlErrorMessage } from '@/components/auth/constants';

import { addRecaptchaTokenToRequest } from '../util';

type Args = {
  usernameOrEmail: string;
  password: string;
  reCaptchaToken: string;
};

export async function emailPasswordLogin({
  usernameOrEmail,
  password,
  reCaptchaToken,
}: Args) {
  try {
    let response = await signIn({
      formFields: [
        {
          id: 'email',
          value: usernameOrEmail,
        },
        {
          id: 'password',
          value: password,
        },
      ],
      options: {
        preAPIHook: async ({ url, requestInit }) => ({
          url,
          requestInit: addRecaptchaTokenToRequest(requestInit, reCaptchaToken),
        }),
      },
    });

    if (response.status === 'FIELD_ERROR') {
      response.formFields.forEach((formField) => {
        if (formField.id === 'email') {
          // Email validation failed (for example incorrect email syntax).
          throw new AuthActionInvalid(formField.error);
        }
      });
      throw new AuthActionInvalid('Invalid login information');
    } else if (response.status === 'WRONG_CREDENTIALS_ERROR') {
      throw new AuthActionInvalid('Wrong username/email password combination');
    } else if (response.status === 'SIGN_IN_NOT_ALLOWED') {
      // the reason string is a user friendly message
      // about what went wrong. It can also contain a support code which users
      // can tell you so you know why their sign in was not allowed.
      throw new AuthActionError(response.reason);
    } else {
      // sign in successful. The session tokens are automatically handled by
      // the frontend SDK.
      return response;
    }
  } catch (err: any) {
    if (err instanceof AuthActionInvalid || err instanceof AuthActionError) {
      throw err;
    }
    if (err.isSuperTokensGeneralError === true) {
      throw new AuthActionError(frontendControlErrorMessage(err.message));
    } else {
      throw new AuthActionError('Oops! Something went wrong.');
    }
  }
}
