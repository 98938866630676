'use client';
import { useForm } from '@tanstack/react-form';
import { useState } from 'react';

import { useAddAuthErrorToast } from '@/components/auth/useAddAuthErrorToast';
import { AuthenticationCard } from '@/components/common/Authentication/AuthenticationCard/AuthenticationCard';
import { IdpLoginButton } from '@/components/common/Authentication/IdpLoginButton/IdpLoginButton';
import { SocialButton } from '@/components/common/Authentication/SocialButton/SocialButton';
import { useReCaptcha } from '@/hooks/useReCaptcha';
import { ReCaptchaAction } from '@/lib/constants';
import { validateIsNotEmpty } from '@/lib/validator';
import Button from '@/rollbar-ui/Button';
import Divider from '@/rollbar-ui/Divider/Divider';
import { InternalLink } from '@/rollbar-ui/Link';

import { useLoginContext } from './LoginContext';
import { PasswordField } from './PasswordField';
import { usePasswordLoginAndRedirect } from './usePasswordLoginAndRedirect';
import { UsernameOrEmailField } from './UsernameOrEmailField';

export default function LoginForm() {
  const { hasSaml } = useLoginContext();
  const passwordLoginAndRedirect = usePasswordLoginAndRedirect();
  const { getReCaptchaToken } = useReCaptcha(ReCaptchaAction.LOGIN);
  const addErrorToast = useAddAuthErrorToast();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm({
    defaultValues: {
      password: '',
      usernameOrEmail: '',
    },
    validators: {
      onSubmit: () => {
        if (!getReCaptchaToken) {
          return 'Recaptcha could not be verified';
        }
        return undefined;
      },
    },
    onSubmit: async ({ value }) => {
      // Handled in form validators
      if (!getReCaptchaToken) return;

      setIsSubmitting(true);
      const { password, usernameOrEmail } = value;

      let reCaptchaToken: string | undefined;
      try {
        reCaptchaToken = await getReCaptchaToken();
      } catch (err) {
        addErrorToast(err, 'ReCaptcha could not be verified');
        setIsSubmitting(false);
        return;
      }

      try {
        await passwordLoginAndRedirect({
          password,
          usernameOrEmail,
          reCaptchaToken,
        });
      } catch (err) {
        addErrorToast(err, 'Something went wrong, please try again');
        setIsSubmitting(false);
      }
    },
  });

  return (
    <>
      <AuthenticationCard.Header>Log in to Rollbar</AuthenticationCard.Header>
      <AuthenticationCard.Body>
        <form
          className="w-full flex-1"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            void form.handleSubmit();
          }}
        >
          <div className="flex flex-col">
            <SocialButton provider="github" title="Log in with GitHub" />
            <SocialButton provider="google" title="Log in with Google" />
            {hasSaml && <IdpLoginButton />}
          </div>
          <Divider>
            <span>Or log in with</span>
          </Divider>
          <div className="flex-1 mt-2">
            <UsernameOrEmailField
              form={form}
              isLoading={form.state.isSubmitting}
              customValidators={{
                onSubmit: (field) =>
                  validateIsNotEmpty('username or email')(field.value),
              }}
            />
          </div>
          <div className="flex-1 mt-2">
            <PasswordField
              form={form}
              isLoading={form.state.isSubmitting}
              customValidators={{
                onSubmit: (field) =>
                  validateIsNotEmpty('password')(field.value),
              }}
            />
          </div>
          <div className="flex justify-end mb-2 -mt-4">
            <InternalLink href={`/auth/forgot-password`}>
              <span className="text-xs">Forgot password?</span>
            </InternalLink>
          </div>
          <form.Subscribe selector={(state) => [state.canSubmit]}>
            {([canSubmit]) => (
              <Button
                className="mb-4 w-full"
                disabled={!canSubmit || isSubmitting}
                isLoading={isSubmitting}
                loadingText="Logging in..."
                type="submit"
                variant="primary"
              >
                <span className="text-sm">Log in</span>
              </Button>
            )}
          </form.Subscribe>
        </form>
        <div className="flex self-end">
          Don&apos;t have an account? &nbsp;
          <InternalLink href={`/signup`}>Sign up now</InternalLink>
        </div>
      </AuthenticationCard.Body>
    </>
  );
}
