import { emailPasswordLogin } from '@/api/auth/signup/emailPasswordLogin';
import { useRedirectAfterAuthentication } from '@/app/hooks/useRedirectAfterAuthentication';
import { useSendMixpanelAttribution } from '@/app/hooks/useSendMixpanelAttribution';

export function usePasswordLoginAndRedirect() {
  const redirectAfterLogin = useRedirectAfterAuthentication();
  const { sendMixpanelAttribution } = useSendMixpanelAttribution();

  return async (loginArgs: Parameters<typeof emailPasswordLogin>[0]) => {
    const result = await emailPasswordLogin(loginArgs);
    sendMixpanelAttribution(result?.user?.id);
    await redirectAfterLogin(result);
  };
}
