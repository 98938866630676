import { FieldValidators, ReactFormApi } from '@tanstack/react-form';

import { FormFieldInfo } from '@/components/common/FormFieldInfo';
import { validator } from '@/lib/validator';
import Input from '@/rollbar-ui/Input';

type PasswordFieldProps = {
  form: ReactFormApi<{ usernameOrEmail: string; password: string }>;
  isLoading: boolean;
  customValidators?: FieldValidators<
    { usernameOrEmail: string; password: string },
    'password'
  >;
};

const fieldValidators: FieldValidators<
  { usernameOrEmail: string; password: string },
  'password'
> = {
  onSubmit: (field) => {
    return validator[field.fieldApi.name](field.value);
  },
};

export function PasswordField({
  form,
  isLoading,
  customValidators = fieldValidators,
}: PasswordFieldProps) {
  return (
    <form.Field name="password" validators={customValidators}>
      {(field) => (
        <>
          <Input
            collapseError={true}
            disabled={isLoading}
            label="Password"
            labelClassName="text-xs text-gray-500"
            onChange={(e) => field.handleChange(e.target.value)}
            placeholder="Your password"
            type="password"
            value={field.state.value}
          />
          <FormFieldInfo field={field} />
        </>
      )}
    </form.Field>
  );
}
