'use client';

import { useRollbar } from '@rollbar/react';
import { FC } from 'react';
import { useMutation } from 'react-query';
import { getAuthorisationURLWithQueryParamsAndSetState } from 'supertokens-web-js/recipe/thirdparty';

import {
  githubLoginCallbackUrl,
  googleLoginCallbackUrl,
} from '@/lib/urls/serverUrls';
import Button from '@/rollbar-ui/Button';
import { useToasts } from '@/rollbar-ui/contexts/ToastsContext';

const buttonImage = {
  github: '/images/email/github-logo.png',
  google: '/images/services/google.svg',
};

const FRONTEND_REDIRECT_URLS = {
  // have mox be the redirect url so that we do not have to change over our oauth app.
  // mox will redirect to the olympus callbacks
  google: googleLoginCallbackUrl(),
  github: githubLoginCallbackUrl(),
};

type SocialButtonProps = {
  provider: 'google' | 'github';
  title: string;
  socialAuthUrl?: string;
};

export const SocialButton: FC<SocialButtonProps> = ({ provider, title }) => {
  const { addToast } = useToasts();
  const rollbar = useRollbar();

  const { mutate: socialSignIn, isLoading } = useMutation(
    async () =>
      getAuthorisationURLWithQueryParamsAndSetState({
        thirdPartyId: provider,

        // This is where Google/Github should redirect the user back after login or error.
        // This URL must match expected redirect uris on oauth configuration as well.
        frontendRedirectURI: FRONTEND_REDIRECT_URLS[provider],
      }),
    {
      onSuccess: (authUrl) => {
        window.location.assign(authUrl);
      },
      onError: (err: any) => {
        rollbar.error('Error redirecting to social login', {
          message: err.isSuperTokensGeneralError && err.message,
        });
        addToast({
          variant: 'error',
          content: <>Please try again, something went wrong</>,
        });
      },
    }
  );

  return (
    <Button
      className="mb-4 font-semibold w-full flex items-center gap-1"
      disabled={isLoading}
      onClick={socialSignIn}
      variant="secondary"
    >
      <img className="w-5" src={buttonImage[provider]} alt="" />
      <span className="text-gray-700 text-sm">{title}</span>
    </Button>
  );
};
