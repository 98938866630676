'use client';

import { LOGIN_PATH, NEW_AUTH_OPT_OUT_COOKIE } from '@/lib/constants';
import { InternalLink } from '@/rollbar-ui/Link';

const baseUrl = process.env.NEXT_PUBLIC_ROLLBAR_BASE_URL;
const cookieDomain = process.env.NEXT_PUBLIC_AUTHN_COOKIE_DOMAIN;

const TWO_MONTHS_SECONDS = 60 * 60 * 24 * 60;

export function AuthenticationOptOut() {
  function optOutOfHappyAuth() {
    document.cookie = `${NEW_AUTH_OPT_OUT_COOKIE}=true; domain=${cookieDomain}; max-age=${TWO_MONTHS_SECONDS}; path=/;`;
  }

  return (
    <p className="text-sm text-gray-500">
      Having issues? Switch back to the{' '}
      <InternalLink
        className="font-bold"
        href={`${baseUrl}/${LOGIN_PATH}`}
        onClick={optOutOfHappyAuth}
      >
        old login
      </InternalLink>
      .
    </p>
  );
}
