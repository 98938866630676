import classNames from 'classnames';
import { ReactNode } from 'react';

import { Card } from '@/rollbar-ui/Card';

import { AuthenticationCardBody } from './AuthenticationCardBody';
import { AuthenticationCardHeader } from './AuthenticationCardHeader';

type Props = {
  children: ReactNode;
  size?: 'md' | 'lg';
};

export function AuthenticationCard({ children, size = 'md' }: Props) {
  return (
    <Card
      className={classNames('w-full min-h-[606px] flex flex-col items-center', {
        'max-w-[444px]': size === 'md',
        'max-w-[500px]': size === 'lg',
      })}
    >
      {children}
    </Card>
  );
}

AuthenticationCard.Header = AuthenticationCardHeader;
AuthenticationCard.Body = AuthenticationCardBody;
