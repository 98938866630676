import classNames from 'classnames';
import { ReactNode } from 'react';

type DividerProps = {
  childLocation?: 'start' | 'center' | 'end';
  children?: ReactNode;
};

export default function Divider({
  childLocation = 'center',
  children,
}: DividerProps) {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-200" />
      </div>
      {children && (
        <div
          className={classNames('relative flex', {
            'justify-start': childLocation === 'start',
            'justify-center': childLocation === 'center',
            'justify-end': childLocation === 'end',
          })}
        >
          <span className="flex bg-white px-2 text-gray-500">{children}</span>
        </div>
      )}
    </div>
  );
}
