import { ReactNode } from 'react';

const ROLLBAR_LOGO_SRC = '/images/common/rollbar-icon-blue.svg';

type Props = {
  children: ReactNode;
};

export function AuthenticationCardHeader({ children }: Props) {
  return (
    <div className="flex flex-col items-center">
      <img
        alt="Rollbar logo"
        aria-hidden="true"
        className="w-14 mx-auto py-6"
        src={ROLLBAR_LOGO_SRC}
      />
      <h4 className="mb-6 font-bold text-gray-600 text-center text-xl">
        {children}
      </h4>
    </div>
  );
}
