'use client';
import { ReactNode, createContext, useContext } from 'react';

type LoginContextType = {
  accountSlug?: string;
  hasSaml: boolean;
};

const LoginContext = createContext<LoginContextType>(undefined!);

export const useLoginContext = () => {
  return useContext(LoginContext);
};

export const LoginProvider = ({
  accountSlug,
  hasSaml = false,
  children,
}: {
  accountSlug?: string;
  hasSaml?: boolean;
  children: ReactNode;
}) => {
  return (
    <LoginContext.Provider value={{ accountSlug, hasSaml }}>
      {children}
    </LoginContext.Provider>
  );
};
