'use client';

import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import Session from 'supertokens-web-js/recipe/session';

import RbLoader from '@/components/common/RbLoader';
import { loginUrl } from '@/lib/urls';

export const TryRefreshComponent = ({ nextUrl }: { nextUrl?: string }) => {
  const router = useRouter();
  const [didError, setDidError] = useState(false);

  useEffect(() => {
    void Session.attemptRefreshingSession()
      .then((hasSession) => {
        if (hasSession) {
          if (nextUrl) {
            router.push(nextUrl);
          } else {
            router.refresh();
          }
        } else {
          router.push(loginUrl());
        }
      })
      .catch(() => {
        setDidError(true);
      });
  }, [router, nextUrl]);

  if (didError) {
    return <div>Something went wrong, please reload the page</div>;
  }

  return <RbLoader />;
};
