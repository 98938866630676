import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export function AuthenticationCardBody({ children }: Props) {
  return (
    <div className="w-full max-w-[360px] flex flex-col items-center flex-1 text-gray-500 text-xs">
      {children}
    </div>
  );
}
